import { ChartBar, ChartPieSlice } from '@phosphor-icons/react';

import {
  DCAMenuIcon,
  EcosystemMenuIcon,
  StakingMenuIcon,
  SwapMenuIcon,
  TokenMenuIcon,
  WrapMenuIcon,
} from '@/components/vector';

const iconSizeClassName = 'h-[22px] w-[22px] shrink-0';

type MenuBase = {
  title: string;
};

export type Menu = MenuBase & {
  icon?: JSX.Element;
  path?: string;
  subMenu?: SubMenu[];
};

export type SubMenu = MenuBase & {
  path: string;
  icon: JSX.Element;
  description: string;
  inPreparation?: boolean;
  suffix?: {
    text: string;
    className: {
      notForceDark: string;
      forceDark: string;
    };
  };
};

const menuConfig: Menu[] = [
  {
    title: 'Chart',
    icon: <ChartBar className={iconSizeClassName} weight="fill" />,
    path: '/pro/swap',
  },
  {
    title: 'Trade',
    subMenu: [
      {
        title: 'Swap',
        path: '/swap',
        icon: <SwapMenuIcon className={iconSizeClassName} />,
        description: 'Optimal Token Trading',
        suffix: {
          text: 'Pro',
          className: {
            notForceDark: 'text-white dark:text-gray-900 bg-teal-500 dark:bg-teal-400',
            forceDark: 'text-gray-900 bg-teal-400',
          },
        },
      },
      {
        title: 'DCA',
        path: '/dca',
        icon: <DCAMenuIcon className={iconSizeClassName} />,
        description: 'Automated Token Trading',
        suffix: {
          text: 'New',
          className: {
            notForceDark: 'text-teal-500 dark:text-teal-400 bg-teal-400/20',
            forceDark: 'text-teal-400 bg-teal-400/20',
          },
        },
      },
    ],
  },
  {
    title: 'Staking',
    subMenu: [
      {
        title: 'Staking',
        path: '/staking',
        icon: <StakingMenuIcon className={iconSizeClassName} />,
        description: 'Deposit and Earn Additional Rewards',
      },
      {
        title: 'Wrap',
        path: '/wrap',
        icon: <WrapMenuIcon className={iconSizeClassName} />,
        description: 'Wrap & Unwrap',
        suffix: {
          text: 'New',
          className: {
            notForceDark: 'text-teal-500 dark:text-teal-400 bg-teal-400/20',
            forceDark: 'text-teal-400 bg-teal-400/20',
          },
        },
      },
    ],
  },
  {
    title: 'Dashboard',
    subMenu: [
      {
        title: 'Token',
        path: '/tokens',
        icon: <TokenMenuIcon className={iconSizeClassName} />,
        description: 'Token Dashboard',
      },
      {
        title: 'Ecosystem',
        path: '/dashboard',
        icon: <EcosystemMenuIcon className={iconSizeClassName} />,
        description: 'Ecosystem Dashboard',
      },
    ],
  },
  {
    title: 'Portfolio',
    icon: <ChartPieSlice className={iconSizeClassName} weight="fill" />,
    path: '/portfolio/token',
  },
];

export const OUTLINKS = [
  { name: 'Docs', href: 'T-docs-link' },
  { name: 'News', href: 'release-gcklay-link' },
  { name: 'Register Token Icon', href: 'Register-Token-Icon-link' },
];

export default menuConfig;
