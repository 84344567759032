import { useMemo } from 'react';
import Web3Utils from 'web3-utils';

export const INVALID_ADDRESS = 'invalid address';

export const useChecksumAddress = (account: string | null) => {
  const checksumAddress = useMemo(() => {
    if (!account) {
      return null;
    }

    let checksumAddressRes = INVALID_ADDRESS;

    try {
      checksumAddressRes = Web3Utils.toChecksumAddress(account);
    } catch (e) {
      return checksumAddressRes;
    }

    return checksumAddressRes;
  }, [account]);

  return { checksumAddress, isInvalidAddress: checksumAddress === INVALID_ADDRESS };
};
