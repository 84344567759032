import clsx from 'clsx';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { SCNRIcon } from '@/components/vector';
import { ZERO_ADDRESS } from '@/defines/token-address';
import useForex from '@/hooks/use-forex';
import usePriceV1 from '@/hooks/use-price-v1';

// TokenRateV2 is used in MainHeaderV2, MenuMobileV2 and MainFooterV2 with only SCNR token icon.
// If you want to use other token icons, you can use it after editing the source.
export default function TokenRateV2({
  address,
  fullWidth = false,
  className,
  forceDark = false,
}: {
  address: '0x8888888888885b073f3c81258c27e83db228d5f3';
  fullWidth?: boolean;
  className?: string;
  forceDark?: boolean;
}) {
  const { t } = useTranslation('common');
  const { usdToReadableCurrency } = useForex();

  const { price } = usePriceV1({ tokenAddress: address });

  return (
    <Link
      passHref
      href={{ pathname: '/swap', query: { from: ZERO_ADDRESS, to: address } }}
      prefetch={false}
    >
      <a className={clsx(className, 'inline-block')}>
        <div
          className={clsx(
            fullWidth ? 'w-full' : '',
            forceDark
              ? 'border-gray-700 text-gray-300'
              : 'border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300',
            'px-3 h-9 border rounded-lg hover:opacity-70 flex flex-row space-x-1.5 items-center justify-center text-sm text-center',
          )}
        >
          {/* Token icon */}
          <SCNRIcon className="w-4 h-4" forceDark={forceDark} />

          <p>≈</p>
          <p>{usdToReadableCurrency(price)}</p>
        </div>
      </a>
    </Link>
  );
}
