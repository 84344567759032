import { useMemo } from 'react';
import useSWR from 'swr';

import { useWalletContext } from '@/context/wallet';
import fetcher from '@/lib/fetcher';

export type AddressName = {
  address?: string | null;
  chainId?: number;
  skip?: boolean;
};

export type AddressNameResponse = {
  addressName: {
    address: string;
    name: string;
    provider: string;
  };
  fetchedAt: number;
};

export const useAddressName = ({ address = '', chainId = 8217, skip = false }: AddressName) => {
  const { wallet } = useWalletContext();
  const _address = useMemo(() => address || wallet?.account, [address, wallet]);

  const { data, mutate } = useSWR<AddressNameResponse>(
    !_address || skip ? null : `${process.env.API_HOST}/accounts/${chainId}/${_address}/name`,
    {
      fetcher,
      refreshInterval: 60 * 1000,
    },
  );

  return {
    data: data?.addressName ?? null,
    mutate,
  };
};
