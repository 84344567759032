import { memo } from 'react';

interface Props {
  className?: string;
}
function Grid1ICon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14V3H14V14H3ZM4.5 4.5H12.5V12.5H4.5V4.5ZM3 21V16H14V21H3ZM4.5 17.5H12.5V19.5H4.5V17.5ZM16 3V21H21V3H16ZM19.5 4.5H17.5V19.5H19.5V4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default memo(Grid1ICon);
