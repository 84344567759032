import { Menu } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';

import { MenuTransition } from '@/components/transition';
import { Grid2Icon, Grid3Icon, Grid4Icon, Grid6Icon } from '@/components/vector';
import Grid1Icon from '@/components/vector/Grid1Icon';
import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import useLocalStorageV2 from '@/hooks/use-local-storage-v2';

export const multichartOptions = [
  {
    icon: <Grid2Icon className="h-5 w-5" />,
    i18nKey: '2 Chart',
    templateId: 2,
    spans: [
      [6, 12], // [col-span, row-span]
      [6, 12],
    ],
  },
  {
    icon: <Grid3Icon className="h-5 w-5" />,
    i18nKey: '3 Chart',
    templateId: 3,
    spans: [
      [12, 6],
      [6, 6],
      [6, 6],
    ],
  },
  {
    icon: <Grid4Icon className="h-5 w-5" />,
    i18nKey: '4 Chart',
    templateId: 4,
    spans: [
      [6, 6],
      [6, 6],
      [6, 6],
      [6, 6],
    ],
  },
  {
    icon: <Grid6Icon className="h-5 w-5" />,
    i18nKey: '6 Chart',
    templateId: 6,
    spans: [
      [4, 6],
      [4, 6],
      [4, 6],
      [4, 6],
      [4, 6],
      [4, 6],
    ],
  },
];

interface MultichartButtonProps {
  className?: string;
}

export default function MultichartButton({ className }: MultichartButtonProps) {
  const router = useRouter();
  const { t } = useTranslation('pro');

  const [showHighlightTooltip, setShowHighlightTooltip] = useState(false);
  const [highlightDismissed, setHighlightDismissed] = useLocalStorageV2(
    LOCAL_STORAGE_KEYS.multiChartHighlightDismissed,
    0,
  );

  useEffect(() => {
    if (!router.isReady || router.query.chartReady !== 'true' || highlightDismissed > 0) return;

    setTimeout(() => setShowHighlightTooltip(true), 500);

    // this is a hack to remove temporal query params `chartReady`
    const { chartReady, ...query } = router.query;
    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true },
    );
  }, [router, highlightDismissed]);

  const closeHighlightTooltip = useCallback(() => {
    setHighlightDismissed(1);
    setShowHighlightTooltip(false);
  }, [setHighlightDismissed]);

  return (
    <Menu as="div" className={clsx(className, 'z-20 relative inline-flex text-left')}>
      {({ open }) => (
        <>
          <Menu.Button
            data-tooltip-id="global-tooltip"
            data-tooltip-content={t('multi chart')}
            data-tooltip-place="bottom"
            data-tooltip-hidden={open || showHighlightTooltip}
            className={clsx('rounded-md', 'text-slate-300 hover:text-slate-400')}
            onClickCapture={closeHighlightTooltip}
          >
            <Grid4Icon className="h-6 w-6" />
          </Menu.Button>

          <MenuTransition>
            <Menu.Items
              className={clsx(
                'absolute top-full translate-y-2 right-0 mt-2 w-28 origin-top-right',
                'divide-y rounded-md shadow-xl ring-1 focus-visible:outline-none ring-slate-50/5',
                'divide-slate-500 bg-dark-card-color',
              )}
            >
              <div className="">
                <Menu.Item key="w/ Swap">
                  {({ active }) => (
                    <button
                      className={clsx(
                        'flex w-full items-center rounded-md px-4 py-3 text-sm justify-between text-slate-300',
                        { 'bg-slate-700': active },
                      )}
                      onClick={() => router.push({ pathname: '/pro/swap' })}
                    >
                      <Grid1Icon className="h-5 w-5" />
                      <span>{t('w/ Swap')}</span>
                    </button>
                  )}
                </Menu.Item>
                {multichartOptions.map(({ icon, i18nKey, templateId }) => (
                  <Menu.Item key={i18nKey}>
                    {({ active }) => (
                      <button
                        className={clsx(
                          'flex w-full items-center rounded-md px-4 py-3 text-sm justify-between text-slate-300',
                          { 'bg-slate-700': active },
                        )}
                        onClick={() =>
                          router.push({
                            pathname: '/pro/chart',
                            query: {
                              templateId,
                            },
                          })
                        }
                      >
                        {icon}
                        <span>{t(i18nKey)}</span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </MenuTransition>

          <MenuTransition show={!open && showHighlightTooltip} slow>
            <div
              className={clsx(
                'absolute top-full translate-y-3 -right-3 origin-top-right rounded-lg flex items-center space-x-2',
                'bg-teal-400 text-gray-900 pl-4 pr-2 py-3 font-semibold text-[13px] min-w-max',
                /** @see https://projects.verou.me/bubbly/ */
                'after:content-[""] after:absolute after:-top-1.5 after:right-6 after:w-0 after:h-0 after:-ml-2 after:-mr-2',
                'after:border-[8px] after:border-transparent after:border-t-0 after:border-b-teal-400',
              )}
            >
              <span>{t('New feature! Try multi chart')}</span>
              <button
                className="p-0.5 rounded-full hover:bg-teal-300/80 group transition-colors"
                onClick={closeHighlightTooltip}
              >
                <XIcon className="h-4 w-auto text-teal-600" />
              </button>
            </div>
          </MenuTransition>
        </>
      )}
    </Menu>
  );
}
